import { Box } from "@mui/material";
import metalFabricationHeader from "../../../../assets/images/productDetails.jpeg";
import metalFabricationHeaderMob from "../../../../assets/images/productDetailsmob.jpeg";
import HeaderBar from "../../../../CommonComponents/HeaderBar/HeaderBar";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";

const MainHeader = ({ handleImageLoad, imageRemoved }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const [breakPoint, setBreakPoint] = useState(window.innerWidth <= 1422);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 1422) setBreakPoint(true);
      else {
        setBreakPoint(false);
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div style={{ position: "relative", maxWidth: "100%" }}>
        {/* <Box
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <img
            onLoad={handleImageLoad}
            alt="metalFabricationHeader"
            src={
              isSmallScreen ? metalFabricationHeaderMob : metalFabricationHeader
            }
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </Box>
        <div
          id="myElement"
          style={{
            position: "absolute",
            top: isVisible ? "30%" : "100%",
            left: isSmallScreen ? "50%" : "25%",
            transform: "translate(-50%, -50%)",
            color: "white",
            fontFamily: "AdorHairline-Bold",
            opacity: isVisible ? 1 : 0,
            animation: isVisible ? "moveUp 1s forwards" : "none",
          }}
        >
          <style>
            {`
          @keyframes moveUp {
            from {
              top: 100%;
            }
            to {
              top: 45%;
            }
          }
        `}
          </style>
          <p
            style={{
              fontSize: isSmallScreen ? 20 : 50,
              lineHeight: 1,
              width: 300,
            }}
          >
            {/* Collection */}
        {/* </p>
        </div>  */}

        <div
          style={{
            // position: "absolute",
            top: "0%",
            paddingLeft: breakPoint ? "3%" : "10%",
            color: "white",
            paddingBottom: "1%",
            backgroundColor: "black",
            fontFamily: "AdorHairline-Bold",
            maxWidth: "100%",
            overflowX: "hidden",
          }}
        >
          <HeaderBar imageRemoved={imageRemoved} />
        </div>
      </div>
    </>
  );
};
export default MainHeader;
