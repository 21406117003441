import { Button, Card, CardMedia, Dialog, Typography } from "@mui/material";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import EditClient from "./EditClient";
import { API_URL } from "../../../../../api";
import axios from "axios";
import ConfirmDelete from "../../../../../CommonComponents/ConfirmDelete";
import DeleteIcon from "@mui/icons-material/Delete";

const ClientCard = ({ client, refetch }) => {
  const [openEditClient, setOpenEditClient] = useState(false);
  const [currentRow, setCurrentRow] = useState("");
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleDeleteCategory = () =>
    axios
      .delete(`${API_URL}/clients/${currentRow.id}`)
      .then(() => {
        setOpenConfirmDelete(false);
        refetch();
      })
      .catch((err) => {});
  return (
    <Card
      style={{
        border: "1px solid #000000",
        padding: 20,
        position: "relative",
        top: 20,
        boxShadow: "none",
        width: 350,
        height: 300,
      }}
    >
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={() => setOpenEditClient(true)}
          style={{ padding: 0, color: "black" }}
        >
          Edit
          <EditIcon style={{ fontSize: 17 }} />
        </Button>
        <Button
          onClick={() => {
            setOpenConfirmDelete(true);
            setCurrentRow(client);
          }}
        >
          <DeleteIcon style={{ color: "red" }} />
        </Button>
      </div>
      <CardMedia style={{ height: 180 }}>
        <img alt="" src={client?.logo} style={{ width: "100%" }} />
      </CardMedia>

      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: 700,
          fontSize: 18,
          color: "#000000",
        }}
      >
        {client?.name}
      </Typography>

      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: 400,
          fontSize: 18,
          color: "#000000",
        }}
      >
        Website link:{" "}
        {client?.website_link ? (
          <a href={client?.website_link} target="_blank" rel="noreferrer">
            {client?.website_link}
          </a>
        ) : (
          "None"
        )}
      </Typography>
      <Dialog onClose={() => setOpenEditClient(false)} open={openEditClient}>
        <EditClient
          client={client}
          setOpenEditClient={setOpenEditClient}
          refetch={refetch}
        />
      </Dialog>
      <Dialog
        onClose={() => setOpenConfirmDelete(false)}
        open={openConfirmDelete}
      >
        <ConfirmDelete
          onClose={() => setOpenConfirmDelete(false)}
          text={currentRow.name}
          onDelete={(e) => handleDeleteCategory(e)}
        />
      </Dialog>
    </Card>
  );
};
export default ClientCard;
