import { Divider, Grid } from "@mui/material";
import { memo } from "react";

const texts = [
  {
    title: "Mission",
    descrition: `Our mission is to deliver exceptional metal fabrication and furnishings services that exceed our clients' expectations.`,
  },
  {
    title: "Vission",
    descrition: `Always ahead of innovative technology and machinery to redefine
  quality and accuracy of unique metal design fabrication.`,
  },
];
const MainTexts = ({ isSmallScreen }) => {
  return (
    !isSmallScreen && (
      <>
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems={!isSmallScreen && "center"}
          justifyContent={!isSmallScreen && "center"}
          textAlign={!isSmallScreen && "center"}
          sx={{
            paddingLeft: { xs: 3, sm: 0 },
            paddingRight: { xs: 3, sm: 0 },
            paddingTop: { xs: 2, sm: 8 },
          }}
        >
          <Grid item xs={12} md={4} id="about-us">
            <h1
              style={{
                fontFamily: "AdorHairline-Bold",
                fontSize: isSmallScreen ? 26 : 32,
              }}
            >
              A Reputation Made of Steel
            </h1>
            <Divider
              style={{
                backgroundColor: "#A8A194",
                width: 230,
                padding: 1,
                marginTop: 5,
                marginBottom: 20,
              }}
              sx={{ display: { sm: "none" } }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            paddingLeft: { xs: 3, sm: 0 },
            paddingRight: { xs: 3, sm: 0 },
            paddingTop: { xs: 2, sm: 0 },
          }}
        >
          <Grid item xs={12} md={6}>
            <h4
              style={{
                fontFamily: "AdorHairline-light",
                fontWeight: 300,
                textAlign: !isSmallScreen && "center",
                marginTop: 1,
                fontSize: isSmallScreen ? 20 : 22,
              }}
            >
              Established in 1970, Alfa Metal Fabrication & Furnishings is a
              subsidiary of Ayad Group, a reputable organization with a heritage
              of over 70 years of experience in steel industry.
            </h4>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{ marginTop: 2 }}
          justifyContent="center"
          alignItems="flex-start"
          sx={{
            paddingLeft: { xs: 3, sm: 0 },
            paddingRight: { xs: 3, sm: 0 },
            paddingTop: { xs: 2, sm: 0 },
          }}
        >
          {texts.map((text) => (
            <Grid
              item
              xs={12}
              md={3.5}
              style={{
                alignItems: !isSmallScreen && "center",
                justifyContent: !isSmallScreen && "center",
                display: "flex",
                flexDirection: "column",
                textAlign: !isSmallScreen && "center",
              }}
            >
              <h1 style={{ fontFamily: "AdorHairline-Bold", margin: 0 }}>
                {text.title}
              </h1>
              <Divider
                style={{
                  backgroundColor: "#A8A194",
                  width: 90,
                  padding: 1,
                  marginTop: 5,
                  marginBottom: 20,
                }}
                sx={{ display: { sm: "none" } }}
              />
              <h4
                style={{
                  fontWeight: 300,
                  textAlign: !isSmallScreen && "center",
                  fontFamily: "AdorHairline-light",
                  fontSize: isSmallScreen ? 20 : 21,
                  marginTop: 1,
                }}
              >
                {text.descrition}
              </h4>
            </Grid>
          ))}
        </Grid>
      </>
    )
  );
};
export default memo(MainTexts);
