import { Box, Divider } from "@mui/material";

import FeaturedProjects from "./Components/FeaturedProjects/FeaturedProjects";
import Achievments from "./Components/Achievments";
import Services from "./Components/Services/Services";
import Clients from "./Components/Clients/Clients";
import MainTexts from "./Components/MainTexts";
import MainHeader from "./Components/MainHeader";
import { useEffect, useState, memo } from "react";
import Footer from "../../CommonComponents/Footer";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

const Home = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  useEffect(() => {
    document.title = "ALFA - Metal Fabrication";
  }, []);
  return (
    <>
      <MainHeader handleImageLoad={handleImageLoad} imageLoaded={imageLoaded} />
      {imageLoaded && (
        <>
          <MainTexts isSmallScreen={isSmallScreen} />

          <Services isSmallScreen={isSmallScreen} />

          <FeaturedProjects isSmallScreen={isSmallScreen} />
          <Achievments isSmallScreen={isSmallScreen} />
          <Box
            sx={{
              paddingLeft: { xs: 0, md: 28 },
              paddingRight: { xs: 0, md: 28 },
              marginTop: { xs: 2, md: 10 },
            }}
          >
            <Divider style={{ backgroundColor: "black" }} />
          </Box>
          <Clients isSmallScreen={isSmallScreen} />
          <Footer />
        </>
      )}
    </>
  );
};
export default memo(Home);
