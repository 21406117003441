import Footer from "../../CommonComponents/Footer";
import Clients from "../Home/Components/Clients/Clients";
import FeaturedProjects from "./Components/FeaturedProjects/FeaturedProjects";
import MachineryList from "./Components/Machinery/Components/MachineryList";
import Machinery from "./Components/Machinery/Machinery";
import MainHeader from "./Components/MainHeader";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

const MetalFabrication = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <MainHeader isSmallScreen={isSmallScreen} />
      <Machinery isSmallScreen={isSmallScreen} />
      <MachineryList />
      <FeaturedProjects isSmallScreen={isSmallScreen} />
      <Clients isSmallScreen={isSmallScreen} />
      <Footer />
    </>
  );
};
export default MetalFabrication;
