import { Card, CardHeader, CardMedia, Grid, Typography } from "@mui/material";
import "../../../../../../CommonCss/styles.css";
import { machines } from "./const";

import { useEffect, useState } from "react";
const MachineryList = () => {
  const [breakPoint, setBreakPoint] = useState(window.innerWidth <= 1422);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 1422) setBreakPoint(true);
      else {
        setBreakPoint(false);
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: "black", paddingBottom: 130 }}
      sx={{
        paddingLeft: { xs: 0, md: breakPoint ? 10 : 24 },
        paddingRight: { xs: 0, md: breakPoint ? 10 : 24 },
      }}
    >
      {machines.map((machine) => (
        <Grid item sx={6} md={4}>
          <Card
            sx={{
              width: 340,
              height: 300,
              backgroundColor: "black",
              borderRadius: 0,
              boxShadow: "none",
              border: "0.7px solid #A8A194",
            }}
          >
            <CardHeader
              // avatar={
              //   <Typography
              //     sx={{
              //       color: "#A7A9AC",
              //       backgroundColor: "black",
              //       fontFamily: "AdorHairline-Light",
              //       fontSize: 40,
              //     }}
              //   >
              //     {machine.number}
              //   </Typography>
              // }
              title={
                <Typography
                  variant="h6"
                  sx={{
                    color: "#A8A194",
                    fontFamily: "AdorHairline-Bold",
                    marginLeft: 2,
                  }}
                >
                  {machine.title}
                </Typography>
              }
              // subheader={
              //   <Typography
              //     sx={{
              //       color: "#A7A9AC",
              //       fontFamily: "AdorHairline-Bold",
              //       fontSize: 13,
              //       marginLeft: 3,
              //     }}
              //   >
              //     {machine.subheader}
              //   </Typography>
              // }
            />
            <CardMedia component="img" image={machine.image} alt="P" />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
export default MachineryList;
