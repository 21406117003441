import { Divider, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";

const ShortDetails = ({ product, isSmallScreen }) => {
  const navigate = useNavigate();

  return (
    <Grid
      item
      xs={12}
      sm={5}
      sx={{ paddingLeft: { xs: 0, sm: 3 } }}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <h2
        style={{ fontFamily: "AdorHairline-Bold", margin: 0, paddingTop: 10 }}
      >
        {product.name}
      </h2>

      <h5
        style={{
          fontFamily: "AdorHairline-Bold",
          margin: 0,
          color: "grey",
          fontWeight: 200,
          marginTop: 20,
        }}
      >
        {product.shortDescription}
      </h5>
      <div
        style={{
          display: "flex",
          flexDirection: "row",

          columnGap: 20,
          marginTop: 10,
        }}
      >
        <h5
          style={{
            fontFamily: "AdorHairline-Bold",
            margin: 0,
            fontWeight: 300,
          }}
        >
          Colors:
        </h5>
        <div style={{ display: "flex", alignItems: "center" }}>
          {product?.colors?.map((color, index) => (
            <div
              key={index}
              style={{
                width: "18px",
                height: "18px",
                borderRadius: "50%",
                backgroundColor: color?.hexacode,
                marginRight: "8px",
              }}
            ></div>
          ))}
        </div>
        <br />
      </div>
      <h4 style={{ fontFamily: "AdorHairline-Bold", marginTop: 20, color: "grey" }}>
        {product.price === 0 || !product.price
          ? ""
          : "EGP " + product?.price?.toLocaleString()}
      </h4>
      {!isSmallScreen && (
        <>
          <Divider sx={{ marginTop: { xs: 3, sm: 3 } }} />

          <h5
            style={{
              fontFamily: "AdorHairline-Bold",
              margin: 0,
              fontWeight: 300,
              display: "flex",
              flexDirection: "row",
              columnGap: 5,
              marginTop: 15,
              marginBottom: 10,
            }}
          >
            Category:
            <p
              style={{ fontWeight: 200, color: "grey", cursor: "pointer" }}
              onClick={() => navigate(`/Categories/${product.category}`)}
            >
              {product.category}
            </p>{" "}
            -
            <p
              style={{ fontWeight: 200, color: "grey", cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/Categories/${product.category}/${product.subcategory}/hasSubCategories=true`
                )
              }
            >
              {product.subcategory}
            </p>
          </h5>
        </>
      )}
    </Grid>
  );
};
export default ShortDetails;
