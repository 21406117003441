import { Box, Card, CardContent, CardMedia, Grid } from "@mui/material";
import "../../../../CommonCss/styles.css";
import { useNavigate } from "react-router";
import { API_URL } from "../../../../api";

const Projects = ({ projects, isSmallScreen }) => {
  const navigate = useNavigate();

  return (
    <Box
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginTop: isSmallScreen ? 20 : 40,
        paddingTop: isSmallScreen ? 20 : 60,
        flexDirection: "column",
        margin: 0,
      }}
      sx={{
        backgroundColor: "#212121",
        paddingBottom: { sx: 10, md: 10 },
      }}
    >
      <Grid
        container
        spacing={4}
        direction="row"
        justifyContent="center"
        // alignItems="center"
        sx={{
          paddingLeft: { xs: 0, md: 28 },
          paddingRight: { xs: 0, md: 28 },
        }}
      >
        {projects?.map((project) => (
          <Grid
            item
            xs={11}
            sm={6}
            md={projects.length > 3 ? 4 : projects.length === 2 ? 5 : 10}
          >
            <Card
              sx={{
                maxWidth: 400,
                backgroundColor: "#212121",
                boxShadow: "none",
                borderRadius: 0,
                // cursor: "pointer",
              }}
              // onClick={() => {
              //   window.scrollTo(0, 0);
              //   navigate(`/project-details/${project.id}`);
              // }}
            >
              <CardMedia
                component="img"
                height="250"
                image={project?.image}
                alt=""
              />
              <CardContent style={{ paddingLeft: 0 }}>
                <h1
                  style={{
                    fontFamily: "AdorHairline-Bold",
                    fontSize: 20,
                    color: "#A8A194",
                  }}
                >
                  {project.title}
                </h1>
                {/* <h5
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: "white",
                  }}
                >
                  {project.shortDescription}{" "}
                  <span
                    onClick={() => navigate(`/project-details/${project.id}`)}
                    style={{
                      color: "#A8A194",
                      padding: 0,
                      cursor: "pointer",
                    }}
                  >
                    More
                  </span>
                </h5> */}
                {/* <h5
                  style={{
                    fontWeight: 400,
                    color: "white",
                    fontFamily: "AdorHairline-light",
                  }}
                >
                  {project.type}
                  {" | "}
                  {project.date}
                </h5> */}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
export default Projects;
