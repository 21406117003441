import { Box, Grid } from "@mui/material";

const MainText = ({ isSmallScreen }) => {
  return (
    <Box
      style={{
        alignItems: !isSmallScreen && "center",
        justifyContent: !isSmallScreen && "center",
        display: "flex",
        paddingTop: 60,
        flexDirection: "column",
        backgroundColor: "#212121",
        paddingBottom: isSmallScreen ? 15 : 70,
      }}
    >
      <h1
        style={{
          fontFamily: "AdorHairline-Bold",
          fontSize: isSmallScreen ? 28 : 32,
          color: "#A8A194",
          paddingLeft: isSmallScreen && 25,
        }}
      >
        Alfa Furnishings
      </h1>

      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent={!isSmallScreen && "center"}
        alignItems={!isSmallScreen && "center"}
      >
        <Grid item xs={11} md={6}>
          <h4
            style={{
              fontWeight: 300,
              textAlign: !isSmallScreen && "center",
              marginTop: 1,
              color: "white",
              paddingLeft: isSmallScreen && 25,
              fontFamily: "AdorHairline-light",
              fontSize: isSmallScreen ? 20 : 23,
            }}
          >
            Alfa's metal furniture for interiors is stylish, durable, and
            crafted from high-quality materials. We use premium-grade
            electrostatic powder coating metal and UV-treated ropes, sling
            fabrics, and texteline.
          </h4>
          <br />
        </Grid>
      </Grid>
    </Box>
  );
};
export default MainText;
