import { useEffect } from "react";
import MainHeader from "./Components/MainHeader";
import MainText from "./Components/MainText/MainText";
import Sectors from "./Components/Sectors";
import Footer from "../../CommonComponents/Footer";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

const Furnishing = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    document.title = "ALFA - Furnishing";
    return () => {
      document.title = "ALFA - Metal Fabrication";
    };
  }, []);
  return (
    <div style={{ backgroundColor: "#212121" }}>
      <MainHeader isSmallScreen={isSmallScreen} />
      <MainText isSmallScreen={isSmallScreen} />
      <Sectors isSmallScreen={isSmallScreen} />
      <Footer />
    </div>
  );
};
export default Furnishing;
