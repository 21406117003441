import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useState } from "react";
const LongDetails = ({ product }) => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        width: "100%",
        typography: "body1",
        paddingRight: { xs: 3, md: 57 },
        marginTop: { xs: 2, md: 5 },
      }}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
            sx={{ "& .MuiTabs-indicator": { backgroundColor: "#86ab1c" } }}
          >
            <Tab
              label="Description"
              value="1"
              style={{
                fontFamily: "AdorHairline-Bold",
                margin: 0,
              }}
              sx={{
                "&.Mui-selected": {
                  color: "black",
                },
              }}
            />
            <Tab
              label="Features"
              value="2"
              style={{ fontFamily: "AdorHairline-Bold", margin: 0 }}
              sx={{
                "&.Mui-selected": {
                  color: "black",
                },
              }}
            />
            <Tab
              label="Specs"
              value="3"
              style={{ fontFamily: "AdorHairline-Bold", margin: 0 }}
              sx={{
                "&.Mui-selected": {
                  color: "black",
                },
              }}
            />
          </TabList>
        </Box>
        <TabPanel
          value="1"
          style={{
            fontFamily: "AdorHairline-Bold",
            margin: 0,
            color: "grey",
            whiteSpace: "pre-line",
            fontWeight: 200,
          }}
        >
          <h5>{product.longDescription}</h5>
        </TabPanel>
        <TabPanel
          value="2"
          style={{
            fontFamily: "AdorHairline-Bold",
            margin: 0,
            color: "grey",
            whiteSpace: "pre-line",
            fontWeight: 200,
          }}
        >
          <h5>{product.features}</h5>
        </TabPanel>
        <TabPanel
          value="3"
          style={{
            fontFamily: "AdorHairline-Bold",
            margin: 0,
            color: "grey",
            whiteSpace: "pre-line",
            fontWeight: 200,
          }}
        >
          <h5>{product.specs}</h5>
        </TabPanel>
      </TabContext>
    </Box>

    // <>
    //   <Grid container style={{ marginTop: 30 }}>
    //     <Grid item xs={12} sm={4} sx={{ paddingRight: { xs: 3, sm: 4 } }}>
    //       <h2 style={{ fontFamily: "AdorHairline-Bold", margin: 0 }}>
    //         Description
    //       </h2>
    //       <h5
    //         style={{
    //           fontFamily: "AdorHairline-Bold",
    //           margin: 0,
    //           color: "grey",
    //           whiteSpace: "pre-line",
    //           fontWeight: 200,
    //           marginTop: 15,
    //         }}
    //       >
    //         {product.longDescription}
    //       </h5>
    //     </Grid>{" "}
    //     <Grid item xs={12} sm={4} sx={{ paddingRight: { xs: 3, sm: 4 } }}>
    //       <h2 style={{ fontFamily: "AdorHairline-Bold", margin: 0 }}>
    //         Features
    //       </h2>
    //       <h5
    //         style={{
    //           fontFamily: "AdorHairline-Bold",
    //           margin: 0,
    //           color: "grey",
    //           whiteSpace: "pre-line",
    //           marginTop: 15,
    //         }}
    //       >
    //         {product.features}
    //       </h5>
    //     </Grid>{" "}
    //     <Grid item xs={12} sm={4} sx={{ paddingRight: { xs: 3, sm: 4 } }}>
    //       <h2 style={{ fontFamily: "AdorHairline-Bold", margin: 0 }}>Specs</h2>
    //       <h5
    //         style={{
    //           fontFamily: "AdorHairline-Bold",
    //           margin: 0,
    //           color: "grey",
    //           whiteSpace: "pre-line",
    //           marginTop: 15,
    //         }}
    //       >
    //         {product.specs}
    //       </h5>
    //     </Grid>
    //   </Grid>
    // </>
  );
};
export default LongDetails;
