import { Button, Card, CardMedia, Dialog, Typography } from "@mui/material";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import EditAchievment from "../EditAchievment/EditAchievment";

const AchievmentCard = ({ achievment, value, refetch, rowIndex }) => {
  const [openEditachievment, setOpenEditAchievment] = useState(false);

  return (
    <Card
      style={{
        border: "1px solid #000000",
        padding: 20,
        position: "relative",
        top: 20,
        boxShadow: "none",
        width: 270,
        height: 270,
      }}
    >
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={() => setOpenEditAchievment(true)}
          style={{ padding: 0, color: "black" }}
        >
          Edit
          <EditIcon style={{ fontSize: 17 }} />
        </Button>
      </div>
      <CardMedia>
        <img alt="" src={achievment?.image} style={{ width: "100%" }} />
      </CardMedia>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 700,
            fontSize: 25,
            color: "#000000",
          }}
        >
          {value} {achievment?.unit}
        </Typography>
      </div>

      <Dialog
        onClose={() => setOpenEditAchievment(false)}
        open={openEditachievment}
      >
        <EditAchievment
          achievment={achievment}
          refetch={refetch}
          value={value}
          setOpenEditAchievment={setOpenEditAchievment}
          rowIndex={rowIndex}
        />
      </Dialog>
    </Card>
  );
};
export default AchievmentCard;
