import { useEffect, useRef, memo } from "react";
import { Card, CardContent, CardMedia, Grid } from "@mui/material";
import metalFabricationHome from "../../../../assets/images/esca2.webp";
import furnishingHome from "../../../../assets/images/furnishing.webp";
import coatingHome from "../../../../assets/images/coating_home.webp";
import "../../../../CommonCss/styles.css";

const services = [
  {
    title: "Metal Fabrication",
    description:
      "We specialize in working with steel, stainless steel, and aluminum sheets and tubes.With a given design, our expert team can manufacture, fabricate, and install various metal products including gates, fences, frames, pergolas, and outdoor lighting.",
    image: metalFabricationHome,
  },
  {
    title: "Furnishings",
    description:
      "Alfa expands into interior furnishings, offering a sleek selection of metal furniture for diverse needs.Crafted from top-tier electrostatic powder coating metal, our pieces ensure durability, corrosion resistance, and aesthetic appeal.",
    image: furnishingHome,
  },
  {
    title: "Electrostatic Powder Coating",
    description:
      "Alfa electrostatic powder coating line offers a high-quality paint solution. Our automated system provides an efficient coating process to ensure the finest coating for aluminum, stainless steel and carbon steel material.",
    image: coatingHome,
  },
];

const Services = ({ isSmallScreen }) => {
  const gridRef = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      // Calculate the maximum height among all card contents
    });

    if (gridRef.current) {
      resizeObserver.observe(gridRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div style={{ paddingBottom: isSmallScreen ? 0 : 50 }}>
      <Grid
        container
        spacing={isSmallScreen ? 0 : 4}
        style={{
          justifyContent: "center",
          display: "flex",
          marginTop: isSmallScreen ? 0 : 10,
        }}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        ref={gridRef}
      >
        {services.map((service, index) => (
          <Grid
            item
            xs={12}
            md={3.5}
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              sx={{
                borderRadius: 0,
                boxShadow: "none",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                padding: isSmallScreen && 1.3,
              }}
            >
              <CardContent
                style={{
                  flex: 1,
                  backgroundColor: "#A8A194",
                  padding: 40,
                  paddingBottom: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h2
                  style={{
                    fontFamily: "AdorHairline-Bold",
                    marginTop: 0,
                    fontSize: 25,
                  }}
                >
                  {service.title}
                </h2>

                <h4
                  style={{
                    fontFamily: "AdorHairline-light",
                    marginTop: 3,
                    fontSize: 17.5,
                    alignSelf: "center",
                  }}
                >
                  {service.description}
                </h4>
              </CardContent>
              <CardMedia
                component="img"
                height="300"
                width="400"
                image={service.image}
                alt="service image"
                style={{ objectFit: "cover" }}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
export default memo(Services);
