import { Card, CardContent, CardMedia } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { API_URL } from "../../../../api";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

const ProductItemList = ({ product, index, isSubCategory }) => {
  const [scrollDirection, setScrollDirection] = useState(null);
  const ref = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    let lastScrollPosition = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;
      setScrollDirection(
        currentScrollPosition > lastScrollPosition ? "down" : "up"
      );
      lastScrollPosition = currentScrollPosition;
    };

    window.addEventListener("scroll", handleScroll);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && scrollDirection === "down") {
            entry.target.classList.add("fadeInCard");
          }
        });
      },
      { threshold: 0.1 }
    );

    observer.observe(ref.current);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      observer.disconnect();
    };
  }, [scrollDirection]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      onClick={() => {
        window.scrollTo(0, 0);
        navigate(`/collection/${product.id}`);
      }}
      ref={ref}
      sx={{
        maxWidth: 350,
        boxShadow: "none",
        borderRadius: 0,
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        border: isSmallScreen && "1px solid #ccc",
      }}
    >
      {/* <style>
        {`
        @keyframes scaleIn {
                from {
                transform: scale(0.9);
                }
                to {
                transform: scale(1);
                }
                }
                .fadeInCard {
                animation:   scaleIn 1s ease-in-out;;
        }
        `}
      </style> */}
      {/* <CardMedia
        component="img"
        image=
        alt={index}
        style={{ width: "100%", height: "50px" }}
      /> */}
      {/* {onHover && ( */}
      <CardContent>
        <img
          src={
            isSubCategory
              ? product?.logo
              : product?.images && product?.images[0]?.image
          }
          style={{ width: "100%", height: "350px" }}
        />
        <h1
          style={{
            fontFamily: "AdorHairline-Bold",
            fontSize: 20,
          }}
        >
          {product.name}
        </h1>

        <h5
          style={{
            fontWeight: 400,
            fontSize: 14,
          }}
        >
          {/* {product.price === 0 ? "" : "EGP " + product.price} */}
        </h5>
      </CardContent>
      {/* )} */}
    </Card>
  );
};
export default ProductItemList;
