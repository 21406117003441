import { Box, Typography } from "@mui/material";
import "./styles.css";
const CategoryItem = ({ category }) => {
  return (
    <div className="image-container">
      <Box
        style={{
          maxWidth: "100%",
          display: "flex",
          height: "350px",
        }}
        className="zoom-container"
      >
        <img
          alt="img2"
          src={category?.logo}
          style={{ width: "100%" }}
          className="zoom-image"
        />
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: "black",
            fontFamily: "AdorHairline-Bold",
            marginTop: 1,
          }}
        >
          {category?.name}
        </Typography>
      </Box>
      {/* <div className="text-overlay">
        <h2>{category.name}</h2>
      </div> */}
    </div>
  );
};
export default CategoryItem;
