import { Grid } from "@mui/material";
import "../../../../CommonCss/styles.css";
import {memo} from "react";

const ImageTextSideCards = ({
  title,
  description,
  image,
  imageFirst = false,
  isSmallScreen,
}) => {
  return imageFirst ? (
    <Grid
      container
      direction="row"
      justifyContent="center"
      spacing={0}
      style={{ padding: isSmallScreen && 10 }}
    >
      <Grid
        item
        xs={12}
        md={4}
        style={{ margin: 0, padding: 0, height: 450, width: "100%" }}
      >
        <img
          alt=""
          src={image}
          style={{ objectFit: "cover", width: "100%", height: "100%" }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        style={{
          display: "flex",
          backgroundColor: "#333132",
          flexDirection: "column",
          color: "#D8D8D8",
          height: !isSmallScreen && 450,
          width: "100%",
          justifyContent: !isSmallScreen && "center",
          paddingTop: isSmallScreen && 30,
          paddingBottom: isSmallScreen && 30,
        }}
      >
        <div
          style={{
            paddingLeft: !isSmallScreen ? "80px" : 15,
            paddingRight: !isSmallScreen ? "80px" : 15,
          }}
        >
          <h1
            style={{
              fontFamily: "AdorHairline-Bold",
              margin: 0,
              lineHeight: 1.3,
              fontSize: isSmallScreen ? 26 : 30,
            }}
          >
            {title}{" "}
          </h1>
          <h4
            style={{
              fontFamily: "AdorHairline-light",
              fontWeight: 300,
              fontSize: isSmallScreen ? 16 : 19,
              marginTop: 15,
            }}
          >
            {description}
          </h4>
        </div>
      </Grid>
    </Grid>
  ) : (
    <Grid container direction="row" justifyContent="center" spacing={0}>
      <Grid item xs={12} md={4}>
        <div
          style={{
            display: "flex",
            backgroundColor: "#333132",
            flexDirection: "column",
            color: "#D8D8D8",
            height: 450,
            width: "100%",
            justifyContent: "center",
          }}
        >
          <div style={{ paddingLeft: "80px", paddingRight: "80px" }}>
            <h1
              style={{
                fontFamily: "AdorHairline-Bold",
                margin: 0,
                lineHeight: 1.3,
                fontSize: isSmallScreen ? 26 : 30,
              }}
            >
              {title}{" "}
            </h1>
            <h4
              style={{
                fontFamily: "AdorHairline-light",
                fontWeight: 300,
                fontSize: 18,
                marginTop: 15,
              }}
            >
              {description}
            </h4>
          </div>
        </div>
      </Grid>
      <Grid
        item
        xs={6}
        md={4}
        style={{ margin: 0, padding: 0, height: 450, width: 500 }}
      >
        <img
          alt=""
          src={image}
          style={{ objectFit: "cover", width: "100%", height: "100%" }}
        />
      </Grid>
    </Grid>
  );
};
export default memo(ImageTextSideCards);
