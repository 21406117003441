import * as React from "react";
import Box from "@mui/material/Box";

import { Grid } from "@mui/material";
import CategoryItem from "./CategoryItem/CategoryItem";
import { useNavigate } from "react-router";

export default function Body({ categories }) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        paddingLeft: { xs: 3, md: 15 },
        paddingRight: { xs: 3, md: 15 },
        paddingTop: { xs: 5, md: 10 },
      }}
    >
      <Grid container spacing={3}>
        {categories.map((category) => (
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              width: "70%",
              cursor: "pointer",
            }}
            onClick={() =>
              navigate(
                category.subcategories.length > 0
                  ? `/Categories/${category.name}/subcategories?hasSubCategories=true`
                  : `/Categories/${category.name}`
              )
            }
          >
            <CategoryItem category={category} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
