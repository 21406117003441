import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import letterLogo from "../../assets/images/letterLogo.webp";
import { useNavigate } from "react-router";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import fullLogo from "../../assets/images/fullLogo.webp";
import { Tooltip } from "@mui/material";
import axios from "axios";
import { API_URL } from "../../api";
import CategoryItem from "./Components/CategoryItem/CategoryItem";
// import fullLogo from "../../assets/images/logoInBlack.png";

const HeaderBar = ({ handleLogoLoad, imageRemoved = false }) => {
  const [collection, setCollection] = React.useState([]);
  let navItems = [
    { title: "HOME", navigate: "/", itemsList: [] },
    {
      title: "METAL FABRICATION",
      navigate: "/Metal-Fabrication",
      itemsList: [],
    },
    { title: "FURNISHINGS", navigate: "/Furnishing", itemsList: [] },
    { title: " ELECTROSTATIC", navigate: "/Electrostatic", itemsList: [] },
    {
      title: "COLLECTION",
      navigate: "/Categories",
      itemsList: collection,
    },
    { title: "CONTACT", navigate: "/Contact-us", itemsList: [] },
  ];
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openMenuMob, setOpenMenuMob] = React.useState(false);

  const navigate = useNavigate();
  const [breakPoint, setBreakPoint] = React.useState(
    typeof window !== "undefined" && window.innerWidth <= 1422
  );

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      function handleResize() {
        if (window.innerWidth <= 1422) setBreakPoint(true);
        else {
          setBreakPoint(false);
        }
      }

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  React.useEffect(() => {
    axios
      .get(`${API_URL}/products/categories/subcategories`)
      .then((response) => {
        const collection = response.data;
        setCollection(
          collection.map((obj) => {
            return {
              item: obj.name,
              navigateto:
                obj.subcategories.length > 0
                  ? `/Categories/${obj.name}/subcategories?hasSubCategories=true`
                  : `/Categories/${obj.name}?hasSubCategories=false`,
              subcategories: obj.subcategories.map((sub) => {
                return {
                  item: sub.name,
                  navigateto: `/Categories/${obj.name}/${sub.name}/?hasSubCategories=true`,
                };
              }),
            };
          })
        );
      })
      .catch();
  }, []);
  const drawer = (
    <Box sx={{ textAlign: "center", backgroundColor: "black", height: "100%" }}>
      <Typography variant="h6" sx={{ my: 2 }} onClick={() => navigate("/")}>
        <img
          alt="home"
          src={fullLogo}
          style={{
            width: "80%",
            height: "auto",
          }}
          onLoad={handleLogoLoad}
        />{" "}
      </Typography>
      <Divider style={{ backgroundColor: "white" }} />
      <List style={{ backgroundColor: "black" }}>
        {navItems.map((item, index) => (
          <React.Fragment key={index} style={{ backgroundColor: "black" }}>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => navigate(item.navigate)}
                style={{ backgroundColor: "black" }}
              >
                <ListItemText primary={item.title} style={{ color: "white" }} />
              </ListItemButton>
              {item.itemsList.length > 0 && (
                <Typography
                  style={{ color: "white", marginRight: 20 }}
                  onClick={() => setOpenMenuMob(!openMenuMob)}
                >
                  {openMenuMob ? "-" : "+"}
                </Typography>
              )}
            </ListItem>
            {openMenuMob &&
              item.itemsList.map((subItem, subIndex) => (
                <ListItem key={subIndex}>
                  <ListItemButton
                    onClick={() => {
                      navigate(subItem.navigateto);
                      handleDrawerToggle();
                    }}
                  >
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "17px",
                        fontFamily: "AdorHairline-Bold",
                        textTransform: "uppercase",
                      }}
                    >
                      {subItem.item}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              ))}
          </React.Fragment>
        ))}
      </List>
      <Divider style={{ backgroundColor: "white" }} />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: 10,
          paddingLeft: 15,
          paddingTop: 10,
          backgroundColor: "black",
        }}
      >
        <InstagramIcon
          style={{ color: "white" }}
          onClick={() =>
            window.open(
              "https://www.instagram.com/alfafurniture.egypt?igsh=MWlobTA1ZDVmOGVweg==",
              "_blank"
            )
          }
        />
        <FacebookRoundedIcon style={{ color: "white" }}  onClick={() =>
            window.open(
              "https://www.facebook.com/share/pcGmtBxSYpsVhR4P/?mibextid=eQY6cl",
              "_blank"
            )
          } />
        <TwitterIcon style={{ color: "white" }} />
        <PinterestIcon style={{ color: "white" }} />
      </Box>
    </Box>
  );

  return (
    <Box>
      <AppBar
        component="nav"
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          marginTop: imageRemoved ? 0 : { xs: 1, sm: 2 },
          paddingTop: imageRemoved ? 3 : 0,
        }}
        position="static"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              marginLeft: -3,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => navigate("/")}
          >
            <img
              alt="home"
              src={letterLogo}
              style={{
                width: "12%",
                height: "auto",
              }}
              onLoad={handleLogoLoad}
            />
            <p
              style={{
                fontSize: 35,
                lineHeight: 1,
                marginLeft: 8,
                fontFamily: "AdorHairline-Bold",
              }}
            >
              ALFA
            </p>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              width: "20%",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            <img
              alt="home"
              src={fullLogo}
              style={{
                width: "100%",
                height: "auto",
              }}
              onLoad={handleLogoLoad}
            />
          </Box>

          <Box
            sx={{
              display: {
                xs: "none",
                md: "flex",
                flexDirection: "row",
                columnGap: 30,
                marginLeft: breakPoint ? "3.5rem" : "6rem",
              },
            }}
          >
            {navItems.map((item, index) => (
              <Tooltip
                sx={{
                  fontSize: "1rem",
                }}
                title={
                  item.itemsList.length > 0 &&
                  item.itemsList.map((subItem, subIndex) => (
                    <CategoryItem subItem={subItem} subIndex={subIndex} />
                  ))
                }
              >
                <Button
                  sx={{ color: "#fff", padding: 0, fontSize: "1rem" }}
                  onClick={() => navigate(item.navigate)}
                >
                  {item.title}
                </Button>
              </Tooltip>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "70%",
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
};
export default HeaderBar;
