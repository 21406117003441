import { Box, CircularProgress, Grid } from "@mui/material";
import MainHeader from "./Components/MainHeader/MainHeader";
import ProductItemList from "./Components/ProductItemList";
import { useEffect, useState } from "react";
import Footer from "../../CommonComponents/Footer";
import axios from "axios";
import { API_URL } from "../../api";
import Body from "./Components/Body/Body";

const Collection = () => {
  const [categories, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "ALFA - Collection";
    return () => {
      document.title = "ALFA - Metal Fabrication";
    };
  }, []);

  const refetch = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/products/categories/subcategories`)
      .then((response) => {
        setLoading(false);
        setCategory(response.data);
      })
      .catch();
  };

  useEffect(() => {
    refetch();
  }, []);
  return (
    <>
      <MainHeader imageRemoved={true} />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress style={{color:"black"}}/>
        </div>
      ) : (
        <Body categories={categories} />
      )}
      <Footer top={60} />
    </>
  );
};
export default Collection;
