import { Box, Button, Grid } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../api";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

const ProjectDetails = () => {
  const { id } = useParams();
  const [project, setProject] = useState({});
  const [isLoading, setLoading] = useState(false);
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/projects/${id}`)
      .then((response) => {
        setLoading(false);
        setProject(response.data);
      })
      .catch();
  }, []);
  return (
    <div style={{ marginBottom: 100 }}>
      {isLoading ? (
        <div></div>
      ) : (
        <>
          <Button
            style={{ color: " #282828", marginBottom: 10 }}
            sx={{
              marginLeft: { xs: 1, md: 7 },
              marginTop: { xs: 1, md: 7 },
              fontSize: { xs: 10, md: 17 },
            }}
            onClick={() => window.history.back()}
          >
            <ArrowBackIosIcon style={{ fontSize: 17 }} />
            Back
          </Button>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            style={{ paddingTop: 60 }}
          >
            <Grid item xs={12} md={12} sx={{ fontSize: { xs: 13, md: 19 } }}>
              <h1 style={{ fontFamily: "AdorHairline-Bold" }}>
                {project.title}
              </h1>
            </Grid>
          </Grid>
          <Box
            sx={{
              paddingLeft: { xs: 3, md: 28 },
              paddingRight: { xs: 3, md: 33 },
              paddingTop: 3,
            }}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h4
              style={{
                fontWeight: 400,
                color: "#A8A194",
                marginTop: 5,
              }}
            >
              {project.type}
              {" | "}
              {project.date}
            </h4>
            <h3
              style={{
                fontWeight: 400,
                marginTop: 25,
              }}
            >
              {project.text1}
            </h3>
            <img
              src={project?.images && project?.images[0]?.image}
              style={{ marginTop: 40, marginBottom: 20 }}
            />

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <img
                    src={project?.images && project?.images[1]?.image}
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h3
                  style={{
                    fontWeight: 600,
                    marginTop: isSmallScreen ? 25 : 100,
                  }}
                >
                  {project.text2SubHeader}
                </h3>
                <h3
                  style={{
                    fontWeight: 400,
                    marginTop: 25,
                  }}
                >
                  {project.text2}
                </h3>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    marginTop: 40,
                  }}
                >
                  <img
                    src={project?.images && project?.images[2]?.image}
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <h4
              style={{
                fontWeight: 400,
                marginTop: 35,
              }}
            >
              {project.text3 === "-" || project.text3 === "None"
                ? " "
                : project.text3}
            </h4>
          </Box>
        </>
      )}
    </div>
  );
};
export default ProjectDetails;
