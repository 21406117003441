import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  Typography,
} from "@mui/material";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import EditCategory from "./EditCategory";
import { API_URL } from "../../../../../api";
import axios from "axios";
import ConfirmDelete from "../../../../../CommonComponents/ConfirmDelete";
import DeleteIcon from "@mui/icons-material/Delete";

const CategoryCard = ({ subcategory, refetch }) => {
  const [openEditCategory, setOpenEditCategory] = useState(false);
  const [currentRow, setCurrentRow] = useState("");
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleDeleteCategory = () =>
    axios
      .delete(`${API_URL}/products/subcategories/${currentRow.id}`)
      .then(() => {
        setOpenConfirmDelete(false);
        refetch();
      })
      .catch((err) => {});
  return (
    <Card
      style={{
        border: "1px solid #000000",
        padding: 20,

        top: 20,
        boxShadow: "none",
        width: 350,
        height: 300,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        <Button
          onClick={() => setOpenEditCategory(true)}
          style={{ padding: 0, color: "black" }}
        >
          Edit
          <EditIcon style={{ fontSize: 17 }} />
        </Button>
        <Button
          onClick={() => {
            setOpenConfirmDelete(true);
            setCurrentRow(subcategory);
          }}
        >
          <DeleteIcon style={{ color: "red" }} />
        </Button>
      </div>
      <CardMedia
        style={{
          height: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          alt=""
          src={subcategory?.logo}
          style={{ width: "70%", height: "100%", objectFit: "cover" }}
        />
      </CardMedia>
      <CardContent>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 700,
            fontSize: 18,
            color: "#000000",
          }}
        >
          {subcategory?.name}
        </Typography>
      </CardContent>

      <Dialog
        onClose={() => setOpenEditCategory(false)}
        open={openEditCategory}
      >
        <EditCategory
          category={subcategory}
          setOpenEditCategory={setOpenEditCategory}
          refetch={refetch}
        />
      </Dialog>
      <Dialog
        onClose={() => setOpenConfirmDelete(false)}
        open={openConfirmDelete}
      >
        <ConfirmDelete
          onClose={() => setOpenConfirmDelete(false)}
          text={currentRow.name}
          onDelete={(e) => handleDeleteCategory(e)}
        />
      </Dialog>
    </Card>
  );
};
export default CategoryCard;
