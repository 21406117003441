import { useEffect, useState } from "react";
import Footer from "../../CommonComponents/Footer";
import ContactDetails from "./Components/ContactDetails/ContactDetails";
import MainHeader from "./Components/MainHeader/MainHeader/MainHeader";

const Contact = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  useEffect(() => {
    document.title = "ALFA - Contact";
    return () => {
      document.title = "ALFA - Metal Fabrication";
    };
  }, []);
  return (
    <div style={{ backgroundColor: "black" }}>
      <MainHeader handleImageLoad={handleImageLoad} />
      {imageLoaded && <ContactDetails />}
      <Footer top={70} />
    </div>
  );
};
export default Contact;
