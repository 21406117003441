import { Box, CircularProgress, Divider, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import ShortDetails from "./Components/ShortDetails";
import LongDetails from "./Components/LongDetails/LongDetails";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../../../../api";
import { useNavigate } from "react-router-dom";

const Details = ({ isSmallScreen }) => {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({});
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  let { productId } = useParams();
  const [selectedImage, setSelectedImage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    document.title = product && `ALFA - ${product.name}`;
    return () => {
      document.title = "ALFA - Metal Fabrication";
    };
  }, [product]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/products/${productId}`)
      .then((response) => {
        setLoading(false);
        setProduct(response.data);
        setMessage("product added successfully");
        setSelectedImage(response.data.images[0]);
      })
      .catch((err) => {
        setLoading(false);
        setError("Failed to Save !");
      });
  }, [productId]);

  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress style={{ color: "black" }} />
    </div>
  ) : (
    <Box
      style={{ marginTop: 40 }}
      sx={{
        paddingLeft: { xs: 3, sm: 20 },
        paddingRight: { xs: 3, sm: 20 },
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="stretch"
      >
        <Grid
          item
          xs={12}
          sm={2}
          sx={{
            display: { xs: "none", sm: "flex" },
            rowGap: 1,
            flexDirection: "column",
          }}
        >
          {product?.images?.map((image, index) => {
            return (
              <Box
                item
                style={{
                  border: "1px solid #ECECEC",
                  width: 150,
                  height: 155,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => setSelectedImage(image)}
              >
                <img
                  alt={index}
                  src={product?.images[index]?.image}
                  style={{
                    width: "80%",
                    maxHeight: "100%",
                  }}
                />
              </Box>
            );
          })}
        </Grid>

        <Grid
          item
          xs={12}
          sm={5}
          style={{
            border: "1px solid #ECECEC",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ width: { xs: 300, sm: 450 }, height: { xs: 300, md: 400 } }}
            style={{
              overflow: "hidden",
            }}
          >
            <img
              alt=""
              src={selectedImage?.image}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          sx={{
            display: {
              xs: product?.images?.length > 1 ? "flex" : "none",
              sm: "none",
            },
            columnGap: 1,
            flexDirection: "row",
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          {product?.images?.map((image, index) => {
            return (
              <Box
                item
                style={{
                  border: "1px solid #ECECEC",
                  width: 900,
                  height: 155,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => setSelectedImage(image)}
              >
                <img
                  alt={index}
                  src={product?.images[index]?.image}
                  style={{
                    width: "100%",
                    maxHeight: "100%",
                  }}
                />
              </Box>
            );
          })}
        </Grid>

        <ShortDetails product={product} isSmallScreen={isSmallScreen} />

        <LongDetails product={product} isSmallScreen={isSmallScreen} />
      </Grid>
      {isSmallScreen && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Divider sx={{ marginTop: { xs: 3, sm: 5 } }} />

          <h5
            style={{
              fontFamily: "AdorHairline-Bold",
              margin: 0,
              fontWeight: 300,
              display: "flex",
              flexDirection: "row",
              columnGap: 5,
              marginTop: 15,
              marginBottom: 10,
            }}
          >
            Category:
            <p
              style={{ fontWeight: 200, color: "grey", cursor: "pointer" }}
              onClick={() => navigate(`/Categories/${product.category}`)}
            >
              {product.category}
            </p>
          </h5>
        </div>
      )}
    </Box>
  );
};
export default Details;
