import { useEffect, useState } from "react";
import Footer from "../../CommonComponents/Footer";
import Main from "./Components/Main";
import MainHeader from "./Components/MainHeader/MainHeader";
const Electrostatic = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  useEffect(() => {
    document.title = "ALFA - Electrostatic";
    return () => {
      document.title = "ALFA - Metal Fabrication";
    };
  }, []);
  return (
    <>
      <MainHeader handleImageLoad={handleImageLoad} imageLoaded={imageLoaded} />
      {imageLoaded && (
        <>
          {" "}
          <Main />
          <Footer top={80} />
        </>
      )}
    </>
  );
};
export default Electrostatic;
