import { Box, Grid } from "@mui/material";

const Machinery = ({ isSmallScreen }) => {
  return (
    <Box
      style={{
        alignItems: !isSmallScreen && "center",
        justifyContent: !isSmallScreen && "center",
        display: "flex",
        paddingTop: 60,
        flexDirection: "column",
        backgroundColor: "black",
        paddingBottom: 70,
      }}
    >
      <h1
        style={{
          fontFamily: "AdorHairline-Bold",
          fontSize: isSmallScreen ? 28 : 32,
          color: "#A8A194",
          paddingLeft: isSmallScreen && 17,
        }}
      >
        MACHINERY{" "}
      </h1>

      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems={!isSmallScreen && "center"}
      >
        <Grid item xs={11} md={6}>
          <h4
            style={{
              textAlign: !isSmallScreen && "center",
              marginTop: 1,
              color: "white",
              fontFamily: "AdorHairline-light",
              fontSize: isSmallScreen ? 20 : 23,
            }}
          >
            At Alfa Metal Fabrication & Furnishings, our focus on cutting-edge
            machinery ensures top-quality products and services. Recent upgrades
            have boosted efficiency, production volume, and overall quality.
          </h4>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Machinery;
